.sitecontainer{
    width: 100%;
    padding: 0 20px;
    height: 64px;
    background: rgb(49, 49, 61);
    border-radius: 8px;


}
.sitecontainer:nth-child(even){
    background-color: transparent;
}
.sitesubbox{
    width: 80px;
    height: 99.5px;
    border-radius: 8px;
}
.sitebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
.line1{
    /* margin-top: 15px; */
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
.line2{
    /* margin-top: 15px; */
    height: 20px;
    width: 300px;
    border-radius: 8px;
}
.line3{
    /* margin-top: 15px; */
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
.shadow {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  width: 16.5rem;
  background-color: #fff;
  overflow-y: scroll;
}

.slide{
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: 99px;
  margin-top: 4rem;
  display: flex;
}

.frazer-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.813);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  color: #fff;
}

select {
  border: 1.5px solid #525266;
  border-radius: 4px;
  background-color: inherit;
  color: #bcbed1;
  width: 70%;
}

input:focus,
input:active {
  border: none;
  outline: none;
}

select option {
  background-color: #212129;
  color: #bcbed1;
  outline: 1px solid transparent;
  border: 1px solid transparent;
}

select:hover {
  cursor: pointer;
}

.frazer-box {
  background: #212129;
  border-radius: 12px;
  width: 40%;
}

.minimum-height {
  min-height: 320px;
}

.frazer-upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1.5rem 1.2rem; */
  height: 64px;
  padding: 24px;
} 
.frazer-upload-header h2 {
  font-size: 16px;
  font-weight: 500;
}

.frazer-upload-header img {
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;
}

.frazer-input {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.paraInput{
  margin-top: -37px;
}
.frazer-input label {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* padding-bottom: 12px; */
  /* padding-top: 1.5rem; */
}

.frazer-input input {
  width: 70%;
  background: none;
  border: 1.5px solid rgb(80, 79, 79);
  padding: 0.5rem;
  border-radius: 5px;
  /* margin-bottom: 1.5rem; */
}

.selectTagDropdown {
  margin-bottom: 20px;
}

.createTagInput {
  margin-bottom: 0;
}

.createTagSelect {
  margin-bottom: 1.5rem;
}

.frazer-input p {
  width: 30%;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1.5px solid #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 31px;
  color: #bcbed1;
}

.fazer-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
  padding: 1rem 1.2rem;
}

.fazer-btn p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.fazer-btn p:hover {
  color: red;
}

.fazer-btn button {
  background: #11d167;
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  height: 36px;
  color: #101014;
  padding: 0 20px 0 24px
  /* padding: 0.5rem 1.2rem; */
}

.unlink-btn {
  border: "1px solid #FFE81A";
  color: "#FFE81A";
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  background: #212129;
  padding: 0.1rem 1.2rem;
}

.progress {
  margin: 0 20px 20px;
  padding: 0;
  width: 409px;
  height: 35px;
  overflow: hidden;
  background: transparent;
  border-radius: 6px;
  border: 1px solid #504f4f;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;

  /* background:rgb(9, 231, 105); */
}

.bg-green-600 {
  --tw-bg-opacity: 1;

  background-color: rgb(22 163 74/var(--tw-bg-opacity));
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 1189px) {
  .frazer-box {
    background: #212129;
    border-radius: 12px;
    width: 70%;
  }
}

@media screen and (max-width: 854px) {
  .frazer-box {
    background: #212129;
    border-radius: 12px;
    width: 80%;
  }

  .frazer-input p {
    width: 40%;
    padding: 0.5rem 1rem;
  }
}

.note-box {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 20px;
  gap: 8px;
  width: 402px;
  height: 36px;
  background: rgba(245, 212, 0, 0.1);

  /* System colors/Yellow 02 */

  border-left: 4px solid #ffe81a;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 1.2rem 4rem;
}

.popup-note {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;

  /* identical to box height, or 12px */

  display: flex;
  align-items: center;

  /* Light colors/Light 1 * */

  color: #fff;
}

.newTagBtn {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 14px 0 12px;
  gap: 4px;
  width: 94px;
  height: 32px;

  /* Light colors/Light 10 */

  border: 1px solid #bcbed1;
  border-radius: 6px;

  /* Inside auto layout */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 1.5rem;
}

.newProductBtn {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 14px 0 12px;
  gap: 4px;
  width: 150px;
  height: 32px;

  /* Light colors/Light 10 */

  border: 1px solid #bcbed1;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 1.5rem;
}

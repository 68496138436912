.videoBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid dimgray;

}

.row:nth-child(3) {
  margin-top: 35px;
}

.line {
  height: 20px;
  width: 100px;
  border-radius: 8px;
}
.timeLine{
  height: 20px;
  width: 70px;
  border-radius: 8px;
}
.box {
  width: 180px;
  height: 99.5px;
  border-radius: 8px;
}
.skeltonCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.skelton-animation {
  overflow: hidden;
  background: #101014;
}

.skelton-animation::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: loading 1s infinite;
  background: linear-gradient(to right, transparent, #525266, transparent);
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

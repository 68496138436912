.loading-screen {
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #2aeb81;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.LoadingLogo {
  position: absolute;
  left: 30%;
  top: 30%;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #31313d;
  position: relative;
}

.redirecting-text {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  text-align: center;
  color: #bcbed1;
  display: flex;
  align-items: center;
}

.redirecting-text p {
  margin-right: 5px;
}

.dots {
  width: 4px;
  height: 4px;
  background-color: #bcbed1;
  border-radius: 50%;
  animation: dot-animation 1s ease-in-out infinite;
  margin-right: 5px;
  margin-top: 10px;
}

.dots:nth-child(1) {
  animation-delay: 0s;
}

.dots:nth-child(2) {
  animation-delay: 0.2s;
}

.dots:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Gerenciar {
  display: flex;
  flex-direction: column;
}

.HeaderNovaPagina{
  background: transparent !important;
}

.selectedComponent{
  background: #212129 !important;
}

.selectedComponent span{
  color: white !important;
}

.HeaderNovaPagina p {
  margin-right: auto;
  text-transform: capitalize;
}

.frameHeader {
  width: 44%;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.tagsColors {
  margin: 5px;
}

.cross {
  margin-left: 5px;
  cursor: pointer;
  font-size: 15px !important;
}

.productsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.deleteIcon {
  cursor: pointer;
}

.GerenciarBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  width: 100%;
  height: 272px;
}

.SubBody {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  width: 50%;
  height: 17rem;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;
}

.SubBody p {
  height: 16px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 192px;
  gap: 16px;
}

.formInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 140px;
  gap: 16px;
}

.createTagAndColor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 62px;
  gap: 12px;
}

.createTagAndColor p {
  height: 14px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.createProduct {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 62px;
  gap: 12px;
}

.createProduct p {
  height: 14px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.dropdownGerenciar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 14px;
  width: 70%;
  height: 36px;
  border: 1px solid #525266;
  border-radius: 4px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  color: #fff;
}

.productInput {
  width: 180%;
}

.dropdownGerenciar::placeholder {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9393b8;
}

.dropdownGerenciar2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 14px;
  width: 100%;
  height: 36px;
  border: 1px solid #525266;
  border-radius: 4px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
}

.dropdownGerenciar2::placeholder {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #00a3ff;
}

.criarTagButton {
  display: flex;
height: 36px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
background: var(--system-colors-green-01, #2AEB81);
}

.productButton {
  margin: 16px 0;
}

.criarTagButton span {
  color: var(--dark-colors-dark-9, #101014);
font-family: Space Grotesk;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 31px; /* 258.333% */
}

.productosList {
  display: flex;
  width: 100%;
  padding: 10px;
  background: #31313d;
  border-radius: 6px;
  margin-top: 12px;
}

.productosList p {
  margin-right: auto;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 31px;
}

.productosList img {
  margin-left: auto;
}

.colorDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 14px;
  width: 70%;
  height: 36px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border: 1px solid #525266;
  border-radius: 4px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  color: #fff;
  margin-right: 20px !important;
}

.colorDropdown option {
  width: 86px;
  height: 12px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #212129;
  background: #212129;
}

@media (max-width: 1027px) {
  .GerenciarBody {
    flex-direction: column;
    height: 100%;
  }

  .SubBody {
    width: 100%;
  }

  .SitesBody {
    margin-top: 28px;
    height: 100%;
  }
}

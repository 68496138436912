.arrow1 {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background: white;
  color: black;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  cursor: pointer;
  align-self: center;
  position: relative;
  top: 45%;
}

.icon {
  padding-right: 2px;
}

.arrow2 {
  background: black;
  color: white;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  align-self: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  bottom: 0;
  margin-left: 99px;
}

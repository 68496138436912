.buttonEdit{
    padding: 10px 10px;
    margin: 10px 0;
}

.titulo{
    color: #72728F;
    font-size: 13px;
    display: inline-block;
    width: 90%;
    cursor: pointer;
}
.arrowUpIcon{
    display: inline;
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
}

.customButtonComponent{
    margin: 10px 0;
}

.tipografia{
    color: #72728F;
    font-size: 13px;
    display: inline-block;
    width: 90%;
    cursor: pointer;
}

.spanTitle{
    color: #72728F;
    font-size: 13px;
}
.body-confirm-email {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: relative;
}

.confirm-email-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 102px 54px 48px;
  width: 460px;
  height: 523px;
  background: #212129;
  border: 1px solid #31313d;
  border-radius: 8px;
}

.heading {
  width: 230px;
  height: 36px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.heading-description {
  width: 323px;
  height: 42px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #c5c7d6;
  margin: 25px 0;
}

.listDescription {
  width: 250px;
  height: 36px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #c5c7d6;
  margin: 10px 0;
}

.listNumbers {
  width: 15px;
  height: 18px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #60f0d3;
  margin-right: 5px;
  margin-top: 10px;
}

.EnterButtonConfirmEmail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  height: 10%;
  background: #60f0d3;
  border-radius: 8px;
  color: #101014;
  font-weight: 600;
  cursor: pointer;
  margin: 25px 0;
}

.descriptionButton {
  width: 100%;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #c5c7d6;
}

.formCircle {
  position: absolute;
  width: 121.36px;
  height: 121.36px;
  top: 10%;
  left: auto;
  right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #31313d;
}

.InnerformCircle {
  position: absolute;
  width: 86.69px;
  height: 86.69px;
  left: 18px;
  top: 16px;
  background: #525266;
  border-radius: 50%;
  margin: auto;
}

.email-confirm-icon {
  position: absolute;
  font-size: 40px;
  top: 28%;
  left: 28%;
  color: #999;
}

.confirm-email-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #525266;
}

.list-container {
  border-bottom: 1px solid #525266;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.list {
  display: flex;
}

.confirm-email {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 720px) {
  .confirm-email-form {
    width: 90%;
  }

  .formCircle {
    top: 20%;
  }
}

.horizontal-liquid-container input[type='range'] {
    appearance: none;
    width: 100%;
    margin: 0;
  }
  
  .horizontal-liquid-container {
    position: relative;
    height: 30px; 
    background-color: #cce0ff; 
    overflow: hidden;
  }
  
  .horizontal-liquid-level {
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 4px; 
  }
  
  .horizontal-liquid-range::-webkit-slider-thumb {
    display: none;
  }

  .skillHeader{
    background: white;
  }

  .headerText{
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-left: 4px;
    color: #6EC1E4;
  }

  .percentage{
    margin-right: 10px;
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center;
    font-size: 11px;
    line-height: 1rem;
  }

  .skillText{
    position: absolute;
    top: 0;
    margin-left: 8px; 
    display: flex;
    height: 100%;
    width: max-content;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* _____________________________________ */
  .rating-star {
    display: flex;
    align-items: center;
  }
  
  .stars {
    font-size: 20px;
  }
  
  .star-container {
    position: relative;
    overflow: hidden;
    color: #ccc;
    display: flex;
    align-items: center;
  }
  
  .star-container::before {
    content: "★★★★★★★★★★";
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
  }
  
  /* .star-container::after {
    content: "⚝⚝⚝⚝⚝";
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
  } */
  
  
  
.SitesHeader {
  margin-left: auto;
  display: flex;
  gap: 16px;
  width: 391px;
  position: relative;
}
.SitesHeader .addIcon{
  width: 12px;
  height: 12px;
  object-fit: none;
  margin-right: 5px;
}
.productSelect {
  border-radius: 8px;
  border: 1px solid #62627a;
  color: #62627a !important;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(110%);
  }
}

.stage1components input {
  transition: transform 0.5s;
}

.stage1components input.movingLeft {
  animation: slideLeft 0.5s forwards;
}

.stage1components input.movingRight {
  animation: slideRight 2s forwards;
}

.inputThird {
  border-radius: 8px;
  border: 1px solid #62627a;
  color: #62627a !important;
  padding: 0 10px;
}

.inputThird:focus {
  border: 1px solid #62627a;
}

.multisIcon1 {
  position: absolute;
  right: 376px;
  top: 148px;
  z-index: 50;
  color: #62627a !important;
}

.multisIcon2 {
  position: absolute;
  right: 132px;
  top: 148px;
  z-index: 50;
}

.heading {
  font-size: 20px;
}

.SitesHeader input {
  padding-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;
  width: 260px;
  height: 42px;
  background: #212129;
  border: 1px solid #62627a;
  border-radius: 8px;
  color: #fff;
}

.SitesHeader input[type="text"] + .close {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
  color: #62627a;
  font-size: 24px;
}

.SitesHeader div {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  width: 251px;
  height: 42px;
  border: 1px solid #fff;
  border-radius: 8px;
}

.SitesHeader input[type="text"]:not(:placeholder-shown) + .close {
  display: block;
}

.SitesHeader input[type="text"]::placeholder {
  width: 66px;
  height: 24px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #62627a;
}

.SitesHeader button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
  gap: 6px;
  width: 149px;
  height: 42px;
  background: #fff;
  border-radius: 6px;
}

.SitesHeader button span {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #101014;
}

.settingsInst {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 31px;
  text-align: center;
  color: #fff;
}

.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 30px;
  height: 30px;
  background: #31313d;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.SitesBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 934px;
  overflow-y: scroll;
  border-radius: 8px;
  -ms-overflow-style: none;

  /* margin-left: 2%; */

  /* for Internet Explorer and Edge */
  scrollbar-width: none;

  /* for Firefox */
}

.SitesBody::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.SitesBodyHeader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 50px;
  background: #212129;
  border-bottom: 1px solid #31313d;
  border-radius: 10px 10px 0 0;
}

.DivisionHeaderBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 42px;
  width: 93px;
  height: 10px;
}

.DivisionHeaderBody p {
  width: 25px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #bcbed1;
}

.DivisionHeaderBody1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 82px;
  margin-left: auto;
}

.DivisionHeaderBody1 p {
  width: 25px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #bcbed1;
}

.SitesBodyBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  width: 100%;
  height: 864px;
  background: #212129;
  border-radius: 0 0 12px 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.SiteComponent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  gap: 86px;
  width: 100%;
  height: 64px;
  background: #31313d;
  border-radius: 8px;
}

.leftSiteComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;
  width: 226px;
  height: 16px;
}

.leftSiteComponent p {
  width: fit-content;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.leftSiteComponent .lockIcon {
  margin-right: 18px;
  color: red;
  height: 16px;
}
.leftSiteComponent .leftSideImg {
  margin-right: 18px;
  color: red;
  height: 16px;
  cursor: pointer;
}

.rightSiteComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 24px;
  width: 56px;
  height: 16px;
}

.rightSiteComponent img:nth-child(1) {
  height: 1.1rem;
  color: #fff;
}

.rightSiteComponent img {
  cursor: pointer;
}

.paginasBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 710px;
  overflow-y: scroll;
  border-radius: 8px;
  -ms-overflow-style: none;

  /* for Internet Explorer and Edge */
  scrollbar-width: none;

  /* for Firefox */
}

.paginasBody::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.stage1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 76px;
  background: #212129;
  border-bottom: 1px solid #31313d;
}

.stage1div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  gap: 24px;
  width: 900px;
  height: 36px;
}

.show3Input {
  width: 900px !important;
}

.hide3Input {
  width: 476px;
}

.stage1 p {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #fff;
}

.stage1components {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 476px;
  height: 36px;
}

.stage2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 46px;
  width: 100%;
  height: 50px;
  background: #212129;
  border-bottom: 1px solid #31313d;
}

.stage2 .tagsotherThanComponent {
  width: 44px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #bcbed1;
  margin: auto;
}

.spanElement {
  width: 44px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #bcbed1;
  margin: auto;
}

.stage2 .tagComponent {
  width: 44px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #bcbed1;
  margin-right: 10px;
}

.stage2components {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 32px;
  width: 386px;
  margin-right: 20px;
}

.stage3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 584px;
  background: #212129;
  border-radius: 0 0 12px 12px;
}

/* .stage1components input {
  padding-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;
  width: 232px;
  height: 36px;
  background: #212129;
  border: 1px solid #62627a;
  border-radius: 8px;
  color: #fff;
} */


/* input:not([size]){
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%236B7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  /* -webkit-print-color-adjust: exact; */

/*
.stage1components input[type="text"] + .close {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
  color: #62627a;
  font-size: 24px;
} */


/* .stage1components input[type="text"]:not(:placeholder-shown) + .close {
  display: block;
}


.stage1components input[type="text"]::placeholder {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #62627a;
}  */

.stage1components button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 6px;
  width: 115px;
  height: 42px;
  background: #fff;
  border-radius: 6px;
}

.stage1components button span {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 31px;
  text-align: center;
  color: #101014;
}

.tagsDropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 191px;
  z-index: 10;
  background: #101014;
  gap: 12px;
  border-radius: 8px;
  width: 224px;
}

.productsDropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 191px;
  right: 120px;
  z-index: 10;
  background: #101014;
  gap: 12px;
  border-radius: 8px;
  width: 229px;
}

.Pagina {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  isolation: isolate;
  width: 286px;
  height: 33px;
}

.Pagina span {
  width: 286px;
  height: 11px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8383a3;
}

.PaginaDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 32px;
  width: 156px;
  height: 10px;
}

.PaginaDiv p {
  width: 156px;
  height: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #fff;
}

.stage3components {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 6px;
justify-content: center;
  /* margin-left: 9rem; */
  flex-wrap: wrap;

  /* 26% 30% 10% 22% 11% */

  /* 31% 25% 10% 22% 11% */

  /* 26% 26% 16% 20% 20% */
}

.stage3components .optionPopup {
  position: absolute;
  background: #31313d;
  top: 2.3rem;
  right: 2.3rem;
  width: 8rem;
  z-index: 10;
  margin: 0;
  list-style: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.stage3components .optionPopup li {
  padding: 11px;
}

.tagsColors {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px 6px;
  gap: 6px;
  height: 30px;
  border-radius: 100px;
}

.tagsColors span {
  height: 12px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.addTags {
  border: 1.5px solid #9393b8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.facebook__parent {
    display: flex;
    align-items: center;
    gap: 8px;
}

.facebook__thumb {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
}

.facebook__thumb_image {
    width: 100%;
    height: 100%;
}

.information {
    width: 100%;
}

.facebook__text {
    background: #F5F5F4;
    width: 100%;
    padding: 20px;
    border-radius: 30px;
}

.facebook__username {
    color: #365899;
    font-weight: 600;
}

.facebook__comment {
    color: #72728F;
    font-weight: 600;
}

.miscellanous {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: -8px;
}

.miscellanous_left {
    display: flex;
    gap: 8px;
}

.miscellanous_text {
    font-weight: 600;
    cursor: pointer;
    color: #90949c;
}

.miscellanous_text p {
    color: #90949c;
}

.miscellanous_text:hover {
    text-decoration-line: underline;
}

.likesComponent {
    display: flex;
    align-items: center;
    position: relative;
    --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    border-radius: 9999px;
    background: #f8fafc;
    padding: 2px 4px;
    width: fit-content;
}

.likesComponent .likeImage {
    width: 20px;
    display: block;
}

.likesComponent .heartImage {
    width: 20px;
    margin-left: 4px;
    margin-right: 2px;
}

.likesComponent span {
    font-size: 12px;
    line-height: 16px;
    padding-top: 2px;
}

.facebook__children {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.facebook__child {
    display: flex;
    align-items: center;
    gap: 8px;
}

.facebook__thumb{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    border-width: 2px;
    overflow: hidden;
}
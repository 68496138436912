.header {
  background: #31313d;
  height: 64px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
}

.leftBox {
  display: flex;
  height: 28px;
  width: 27.5rem;
}

.leftIcon {
  background: #525266;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 16px;
}

.searchInput{
  border-bottom: 1px solid #72728F;
  outline: 0;
  background: #31313D;
  color: #fff;
  padding: 4px;
}

.responsiveScreenIcon{
  height: 28px;
  width: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.responsiveScreenIcon img{
  height: 24px;
  width: 24px;
}

.reloadLeft{
  cursor: pointer;
}
.reloadRight{
  transform: scaleX(-1);
  cursor: pointer;
}

.middleBox {
  display: flex;
  width: 27.5rem;
}

.middleBox .resolution {
  background: #414152;
  display: flex;
  gap: 24px;
  height: 44px;
  padding: 16px 10px;
  align-items: center;
  border-radius: 4px;

  /* width: 16.25; */
}

.middleBox .resolution .icons {
  display: flex;
  gap: 12px;
}

.middleBox .resolution .icons .image {
  height: 24px;
  width: 24px;
}

.middleBox .resolutionRange {
  display: flex;
  gap: 12px;
  color: #e1e2eb;
  font-size: 14px;
}

.middleBox .resolutionRange .textColor {
  color: #72728f;
}

.rightBox {
  display: flex;
  gap: 48px;
  align-items: center;
}

.rightBox .reload {
  display: flex;
  gap: 12px;
}

.rightBox .reload img {
  height: 24px;
  width: 24px;
}

.rightBox .checkCircle {
  display: flex;
  gap: 16px;
}

.rightBox .checkCircle .currentTime {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.rightBox .checkCircle .currentTime span {
  color: #e1e2eb;
  margin-left: 4px;
}

.rightBox .checkCircle .Buttons {
  display: flex;
  gap: 12px;
}

.BodyOfPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.813);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  width: 100%;
  height: 100%;
}

.headerOfPopup {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 852px;
  height: 64px;
  background: #212129;
  border-bottom: 1px solid #31313d;
  width: 100%;
}

.headerOfPopup img{
  cursor: pointer;
}

.FrameOfPopup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  height: 100%;
  width: 100%;
}

.sidebarFramePopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  height: 100%;
  background: #212129;
  border-right: 1px solid #31313d;
}

.mainframePopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #212129;
}

.mainSideBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  height: 100%;
}

.sideFrameTop {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 16px;
  height: 50%;
  border-bottom: 1px solid #31313d;
  border-top: 1px solid #31313d;
  overflow-y: scroll;
}

.sideFrameBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 16px;
  height: 188px;
}

.sideFrameComponent {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;

  /* width: 100%; */
  height: 46px;
}

.sideFrameComponent img{
  cursor: pointer;
}

.novapasta {
  width: max-content;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 1rem;
}

.DefinitionOfHeader {
  height: 12px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.toggleFolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 20px;
  gap: 8px;
  width: 264px;
  height: 32px;
}

.folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: 224px;
  height: 16px;
}

.folder p {
  width: 200px;
  height: 8px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #bcbed1;
}

.files {
  color: #bcbed1;
  font-family: 'Space Grotesk';
  font-size: 12px;
  font-weight: 400;
  padding-left: 20px;
  cursor: pointer;
}

.folderFileImg{
  display: inline-block;
  margin-right: 2px;
}

.subFolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 36px;
  gap: 8px;
  width: 264px;
  height: 32px;
}

.uploadImage {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 8px;
  width: 100%;
  height: 100%;
  background: #101014;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.uploadImage label {
  flex-basis: 30%;
}

.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  gap: 12px;
  width: 100%;
  height: 76px;
  background: #212129;
  border-top: 1px solid #31313d;
}

/* .mainframe11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px;
  gap: 8px;
  height: 481px;
  overflow-y: scroll;
}

.mainframe111 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  height: 811.31px;
}

.mainframe1111 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 18.67px;
  height: 146.26px;
} */

.uploadFile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 311px;
  height: 146.26px;
}

.uploadFileComponent {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 45px;
  gap: 8px;
  width: 311px;
  height: 146.26px;

  /* Dark colors/Dark 7 * */

  background: #31313d;

  /* Dark colors/Dark 5 * */

  border: 1px dashed #525266;
  border-radius: 4px;
}

.uploadFileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 1px;
  gap: 14px;
  background: #31313d;

  /* Dark colors/Dark 5 * */
  height: fit-content;
  border: 1px dashed #525266;
  border-radius: 4px;
  cursor: pointer;
}

.uploadFileLabel p {
  width: 174px;
  height: 17px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8383a3;
}

.uploadFileLabel p span{
  color: blue;
}

.deleteButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  width: 174px;
  height: 36px;
  border: 1px solid #ff5c6c;
  border-radius: 6px;
}

.deleteButton p {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #ff5c6c;
}

.selectButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  width: 120px;
  height: 36px;
  background: #fff;
  border-radius: 6px;
}

.selectButton span {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  color: #101014;
}

label {
  margin: 0;
}

.uploadedImage {
  width: 200px;
  height: 155px;
  border-radius: 10px;
}

.uploadedImage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.inputComponent{
    display: flex;
    align-items: center;
    gap: 16px;
    background: #F0F0F5;
    padding: 8px;
    width: 100%;
    position: sticky;
    top: 0;
}
.input{
    background: #F0F0F5;
    color: #72728F;
    outline: 0;
    font-size: 14px;
}

.listOfComponents{
    background: #fff;
    padding: 0 14px;
    overflow-y: scroll;
}

.favourite{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f5;
    padding: 24px 0;
}

.favourite span{
font-size: 12px;
color: #72728f;
}

.selectedComponent{
 padding: 24px 0;
 display: flex;
 justify-content: space-between;
 background: white !important;
}

.selectedComponent span{
 font-size: 14px;
 color: #72728f;
 cursor: pointer;
}
.arrowDownIcon{
    cursor: pointer;
}
.builderComponent{
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 8px;
    flex-direction: column;
    align-items: center;
    background: #F7F8FA;
    padding: 8px;
    border-radius: 8px;
    border: 2px solid #F0F0F5;
}

.builderComponent:hover{
    background: #fff;
}

.starIcon{
    align-self: flex-end;
}

.builderItem{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.builderItem img{
    width: 20px;
    height:  20px;
}
.builderItem span{
    font-size: 12px;
    margin-top: 8px;
    color: #72728F;
}
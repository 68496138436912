#slide {
  position: absolute;
  left: -16.5rem;
  width: 16.5rem;
  z-index: -1;
  -webkit-animation: slideleft 0.3s forwards;
  animation: slideleft 0.3s forwards;
}

@-webkit-keyframes slideleft {
  90% {
    z-index: -1;
  }

  100% {
    left: 5.05rem;
    z-index: 1;
  }
}

@keyframes slideleft {
  90% {
    z-index: -1;
  }

  100% {
    left: 5.05rem;
    z-index: 1;
  }
}

@-webkit-keyframes slideright {
  100% {
    left: -16.5rem;
    z-index: 1;
  }
}

@keyframes slideright {
  100% {
    left: -16.5rem;
    z-index: 1;
  }
}

.sideBar {
  background: black;
  color: white;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 16px;
  width: 105px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 4rem;
  overflow-y: scroll;
}

.sideBar .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  margin: 9px;
  cursor: pointer;
}

.sideBar .logo span {
  font-size: 23px;
  line-height: 24px;
  margin-top: 90px;
}

.sideBar .icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px 0;
  border-radius: 4px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  padding: 10px;
}

.sideBar .icons:hover {
  background: #72728f !important;
}

.sideBar span {
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
}

.sideBar .icon {
  width: 20px;
  height: 20px;
}

.publishButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6px 0;
  border-radius: 4px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  padding: 10px;
  margin-top: 32px;
  background: #0fb85b;
}

.publishButton:hover {
  background: #72728f;
}

.settingsContainer{
  display: flex;
  gap: 4px;
  margin-top: 32px;
}

.settingsIcon {
  cursor: pointer;
  transform: scale(0.95);
}

select:focus {
  border: none;
  border-color: none;
}

select {
  width: 4.48rem;
}

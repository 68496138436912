.right-component-redefine {
  width: 40%;
  height: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-icon-redefine {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.hyperLinkStyle strong{
  text-decoration: underline;
  color: #60F0D3;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .right-component-redefine {
    width: 90%;
    align-items: flex-start;
    margin-top: 10vh;
  }
}

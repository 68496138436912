.frazer-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.813);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  color: #fff;
}

.frazer-box {
  display: flex;
  width: 640px !important;
  /* height: 320px; */
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--dark-colors-dark-8, #212129);
}

.frazer-upload-header {
  display: flex;
  width: 640px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-colors-dark-8, #212129);
  border-radius: 12px
}

.frazer-upload-header h2 {
  color: var(--light-colors-light-1, #FFF);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}

.frazer-upload-header img {
  cursor: pointer;
}

.minimum-height {
  display: flex;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px !important;
}

.connectInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: 195px; */
}

.frazer-input {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.videoUploadbtn{
  margin-left: 24px;
    border: 1px solid #BCBED1;
    padding: 0 20px;
    border-radius: 8px;
    height: 36px;
    margin-bottom: 24px;
    color: #BCBED1;
}


.frazer-input label {
  color: var(--light-colors-light-1, #FFF);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
}

.frazer-input input {
  display: flex;
  width: 409px !important;
  height: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.frazer-input select {
  display: flex;
  width: 409px !important;
  height: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.frazer-input p {
  width: 30%;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1.5px solid #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 31px;
  color: #bcbed1;
}

.note-box {
  display: flex;
  padding: 12px 20px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border-left: 4px solid var(--system-colors-yellow-02, #FFE81A);
  background: rgba(245, 212, 0, 0.10);
  margin: 0 1.5rem 4rem !important;
  width: inherit;
}

.popup-note {
  color: var(--light-colors-light-1, #FFF);
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 12px */
  flex: 1 0 0;

}

.fazer-btn {
  display: flex;
  padding: 16px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: var(--dark-colors-dark-8, #212129);
  border-radius: 0 0 12px 12px;
}

.fazer-btn p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.fazer-btn p:hover {
  color: red;
}

.fazer-btn button {
  background: #11d167;
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  height: 36px;
  color: #101014;
  padding: 0 20px 0 24px
    /* padding: 0.5rem 1.2rem; */
}


@media screen and (max-width: 854px) {
  .frazer-box {
    background: #212129;
    border-radius: 12px;
    width: 80%;
  }

  .frazer-input p {
    width: 40%;
    padding: 0.5rem 1rem;
  }
}
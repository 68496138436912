@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap');

body,
html {
  height: 100%;
  overflow: auto;
}

html,
body {
  font-size: revert;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
 font-size: unset;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input[type="number"] {
  padding: 0;
}

/* Hide the scrollbar on all browsers */
::-webkit-scrollbar {
  width: 0;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #000 #f5f5f5;
}

html::-moz-scrollbar-thumb {
  background-color: #000;
}

html::-moz-scrollbar-track {
  background-color: #f5f5f5;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Work Sans",
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center-align {
  align-items: center;
}

.center-content {
  justify-content: center;
}

.center-middle {
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.buttonHover:hover {
  background-color: #0855c9;
  transition: background-color 0.2s ease-in-out;
}

#addImage {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.divider-text {
  padding: 0 10px;
  font-weight: bold;
}

/*
.divider-line {
  flex-grow: 1;
  border-width: 5px;
  border-color: black;
} */

.plus *,
.edit * {
  color: #72728F;
}

.resizable-div {

  /* border: 2px solid red; */
  /* width: 200px;
  height: 200px; */
  border-radius: 3px;
  /* min-width: 15px;
  min-height: 15px; */
  overflow-y: scroll;
  padding-top: 1rem;
  
}

.resizer-child {
  background: rgb(91, 90, 90);
  width: 100%;
  border-radius: 10px;
}

.rl .resizer-child ,
.rr .resizer-child  {
 height: 90px;
}

.rt .resizer-child ,
.rb .resizer-child  {
 width: 90px;
 height: 100%;
 transition: all 0.2 linear;
}

.rt:hover .resizer-child,
.rb:hover .resizer-child{
  width: 120px
}

.rl:hover .resizer-child,
.rr:hover .resizer-child{
  height: 120px
}

.resizer {
  position: absolute;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rrstyles {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 9px;
}

.rtstyles {
  cursor: row-resize;
  height: 9px;
  left: 0;
  top: 0;
  width: 100%;
}

.rbstyles {
  cursor: row-resize;
  height: 9px;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 0%;
}

.rlstyles {
  cursor: col-resize;
  height: 100%;
  top: 0;
  width: 9px;
}

#mobileBreakpoint::placeholder,
#tabletBreakpoint::placeholder {
  color: gray;
}


@import url('https://fonts.googleapis.com/css?family=Space+Grotesk');

.custom-background {
  background-color: #fff;
}

.dark .custom-background {
  background-color: #000;

  /* background-color: #fff; */
}

body::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

body {
  -ms-overflow-style: none;

  /* for Internet Explorer and Edge */
  scrollbar-width: none;

  /* for Firefox */
}

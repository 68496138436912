.body-container {
  background-color: #000;
}

.email-icon-signup {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.password-icon-signup {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.togglePassword-icon-signup {
  position: absolute;
  top: 65%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.name-icon-signup {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.inputDetailsTel {
  padding: 8px;
  margin-right: 5px;
  height: 48px;
  background: #f0eded;
  border: 1px solid #31313d;
  border-radius: 8px;
  color: #000;
}

.dark .inputDetailsTel {
  background: #212129;
  border: 1px solid #31313d;
  color: #e3fcef;
}

.inputDetailsTel::placeholder {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9393b8;
}

.lembrar-signup {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #808080;
  margin-left: 15px;
}

.dark .lembrar-signup {
  color: #c5c7d6;
}

strong {
  text-decoration: underline;
  color: #60f0d3;
  cursor: pointer;
}

.checkBox {
  display: flex;
  margin: 15px 0;
  align-items: center;
}

.checkBox span {
  text-decoration: underline;
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

@media screen and (max-width: 720px) {
  .lembrar-signup {
    font-size: 11px;
  }
}

.sideBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 32px;
  gap: 20px;
  width: 248px;
  height: 100vh;
  background: #31313d;
  position: fixed;
  z-index: 1000;
}

.sideBarContainerClosed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 32px;
  gap: 20px;
  width: 5rem;
  height: 100vh;
  background: #31313d;
  position: fixed;
  overflow-x: hidden;
}

.atomicatLogo {
  position: absolute;
  width: 116.18px;
  height: 19.95px;
  left: 28px;
  top: 34.82px;

  /* background: linear-gradient(321.63deg, #8672FB -9.67%, #60F0D3 30.11%, #C6FF1A 91.76%); */
}

.MfromLogo {
  position: absolute;
  width: 21.2px;
  height: 16.21px;
  left: 73.72px;
  top: 38.97px;

  /* background: linear-gradient(312.48deg, #8672FB 11.77%, #60F0D3 48.9%, #C6FF1A 106.43%); */
}

.LogoBorder {
  position: absolute;
  width: 46px;
  height: 46px;
  left: 15px;
  top: 22px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Frame1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  width: 248px;
  height: 438px;
}

.LogoContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 32px 20px 28px;
  gap: 14px;
  width: 248px;
  height: 90px;
  border-bottom: 1.5px solid #525266;
}

.RedirectsIcon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 248px;
  height: 328px;
}

.EachRedirectcomponent {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 28px;
  gap: 12px;
  width: 248px;
  height: 48px;
  cursor: pointer;
}

.textforIcon {
  /* height: 11px; */
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #d3d4e0;
}

.selectDropDown {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #d3d4e0;
  width: 8rem;
}

.Frame2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  width: 248px;
  height: 150px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-top: 1.5px solid #525266;
}

.LandingDashboard {
  display: flex;
}

.RightComponentLanding {
  margin-bottom: 24px;
}

.RightComponentLanding::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.headerHome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 44px;
  gap: 8px;
  width: 100%;
  height: 90px;
}

.DivHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 44px 32px 44px;
  gap: 24px;
  width: 100%;
  display: flex;
  height: 744px;
  flex-shrink: 0;
}

.HeaderIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 24px;
  width: 321px;
  height: 42px;
}

.buttonHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  width: 201px;
  height: 42px;
  background: #60f0d3;
  border-radius: 8px;
  position: relative;
}

.buttonHeader p {
  width: 127px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #101014;
}

.HeaderText {
  color: #FFF;
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.dark .HeaderText {
  color: #fff;
}

.frameNotificationProfile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 96px;
  height: 42px;
}

.notification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 42px;
  height: 42px;
  border: 2px solid #525266;
  border-radius: 8px;
  background: #000 !important;
}

.notification-icon {
  max-width: 20px;
}

.dark .notification {
  background: #fff;
}

.avatar {
  width: 42px;
  height: 42px;
  fill: #9393B8;
}

.notifyBar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 8px;
  width: 100%;
  height: 54px;
  /* background: rgba(245, 212, 0, 0.1); */
  /* border-left: 4px solid #ffe81a; */
  border-radius: 8px;
}

.notifyBar p {
  width: 1064px;
  height: 14px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #000;
}

.dark .notifyBar p {
  color: #fff;
}

.arrowIcon {
  position: absolute;
  left: 5.33%;
  top: 39.17%;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 48px;
  top: -12px;
  left: 300px;
}

.toggleSwitchCheckbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitchLabel {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 24px;
  background-color: #d1d1d1;
}

.toggleSwitchInner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  background-color: #60f0d3;
  height: 24px;
  border-radius: 24px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.dark .toggleSwitchInner {
  background-color: #fff;
}

.toggleSwitchCheckbox:checked+.toggleSwitchLabel .toggleSwitchInner {
  margin-left: 0%;
}

.toggleSwitchSwitch {
  display: block;
  position: absolute;
  top: 24px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  transform: translate3d(2px, 2px, 0);
  transition: transform 0.3s ease-in-out 0s;
}

.toggleSwitchCheckbox:checked+.toggleSwitchLabel .toggleSwitchSwitch {
  transform: translate3d(26px, 2px, 0);
}

.frameGrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  width: 100%;
  height: 634px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.CreateSite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 75%;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;
  overflow-y: scroll;
  align-self: stretch;
  -ms-overflow-style: none;

  /* for Internet Explorer and Edge */
  scrollbar-width: none;

  /* for Firefox */
}

.CreateSite::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.NewsAndSuggestComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  flex:1 0 0;
  align-self: stretch;
}

.NewsComponent {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  background: linear-gradient(180deg, #212129 0%, #1F1F26 100%);
}

.Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  gap: 24px;
  width: 100%;
  height: 36px;
}

.Heading p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.SearchIcon {
  display: flex;
  align-items: center;
  gap: 16px;
}

.searchIconImage{
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;

}

.SearchIcon img {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}

.SearchIcon button {
  display: flex;
  height: 36px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--light-colors-light-1, #FFF);
}

.SearchIcon button img {
  width: 14px;
  height: 14px;
  color: black !important;
}

.SearchIcon button p {
  color: var(--dark-colors-dark-9, #101014);
  text-align: center;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  /* 221.429% */
}

.NewPageComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0px;
  gap: 12px;
  width: 100%;
  align-self: stretch;
}

.NewPageFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px;
  gap: 8px;
  background: #31313d;
  border-radius: 8px;
}

.NewsAndSuggestFrame {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  overflow-y: scroll;
  background: linear-gradient(180deg, #212129 0%, #1F1F26 100%);
}

.NewsAndSuggestFrame p {
  align-self: stretch;
  color: var(--light-colors-light-5, #E1E2EB);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 19.6px */
}

.NewsAndSuggestFrame::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.NewsContainer {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.NewsInnerContainer {
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border-left: 4px solid var(--dark-colors-dark-1, #9393B8);
  background: #31313D;
}

.frame211 p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.NewsHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.NewsSubHeading {
  align-self: stretch;
  color: var(--light-colors-light-5, #E1E2EB);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 19.6px */
}

.NewsHeading span {
  align-self: stretch;
  color: var(--light-colors-light-5, #E1E2EB);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.NewsHeading span p {
  align-self: stretch;
  color: var(--light-colors-light-5, #E1E2EB);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 140%;
  /* 19.6px */
}

.NewsFooter {
  width: 324px;
  height: 12px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #bcbed1;
}

.suggestionButton {
  display: flex;
  height: 36px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--light-colors-light-1, #FFF);
}

.suggestionBox {
  display: flex;
  padding: 10px 12px;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--dark-colors-dark-4, #62627A);
}

.suggestionBox p {
  flex: 1 0 0;
  color: var(--dark-colors-dark-3, #72728F);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.suggestion-Container {
  display: flex;
  height: 221px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.inputAndLabelComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
}


.inputLabelSugg {
  align-self: stretch;
  color: var(--light-colors-light-5, #E1E2EB);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.NewPageInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
  width: 100%;
}

.NewPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.NewPageComponents {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  padding-right: 0px;
  gap: 12px;
  overflow-x: scroll;
  -ms-overflow-style: none;

  /* for Internet Explorer and Edge */
  scrollbar-width: none;

  /* for Firefox */
}

.NewPageComponents::-webkit-scrollbar {
  display: none;

  /* for Chrome, Safari and Opera */
}

.HeaderComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.Site-Url {
  display: flex;
  align-items: center;
  gap: 6.516px;
}

.Site-Url p {
  color: #9393B8;
  font-size: 12px;
  font-family: "Space Grotesk";
  font-weight: 400;
}

.Site {
  color: var(--light-colors-light-1, #FFF);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
}

.ButtonComponent {
  display: flex;
  align-items: center;
  gap: 16px;
}

.ButtonComponent .optionComponent {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.ButtonComponent .optionComponent img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.NovaPagina {
  display: flex;
  height: 36px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--light-colors-light-1, #FFF);
}

.NovaPagina img {
  width: 14px;
  height: 14px;
}

.NovaPagina p {
  color: var(--light-colors-light-1, #FFF);
  text-align: center;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  /* 221.429% */
}

.textButton {
  color: var(--light-colors-light-1, #FFF);
  text-align: center;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  /* 221.429% */
}

.NewPage {
  display: flex;
  width: 215px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: var(--dark-colors-dark-8, #212129);
}

.imageDesign {
  height: 116.062px;
  align-self: stretch;
  border-radius: 4.344px 4.344px 0px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), url(../../assets/images/landingDashboard/Rectangle\ 3.png), lightgray 0px -0.094px / 100% 108.713% no-repeat;
}

.NewPageDescComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 215px;
}

.EachComponentBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  isolation: isolate;
  width: 215px;
  height: 109.89px;
}

.NewPageDesc {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
}

.NewPageUrlBody {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 42.352px;
  align-self: stretch;
  border-radius: 0px 0px 4.344px 4.344px;
  border-top: 1px solid var(--dark-colors-dark-5, #525266);
}

.NewPageTitleComponent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.NewPageTitleComponent img {
  width: 13.031px;
  height: 13.031px;
}

.NewPageTagComponent {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.NewPageTitle {
  display: flex;
  align-items: center;
  gap: 6.516px;
}

.NewPageTitle .ellpise {
  font-size: 6.5px;
  display: flex;
  width: 10.86px;
  height: 10.86px;
  justify-content: center;
  align-items: center;
  gap: 8.688px;
}

.NewPageTitle .ellpise span {
  width: 6.516px;
  height: 6.516px;
  flex-shrink: 0;
  fill: var(--system-colors-green-02, #11D167);
}


.NewPageTitle p {
  color: #FFF;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.NewPageTag1 {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  /* border: 1px solid var(--atomic-host-purple-medium, #8672FB); */
  /* background: rgba(109, 93, 204, 0.10); */
}

.NewPageTag2 {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  /* border: 1px solid var(--system-colors-yellow-02, #FFE81A);
  background: rgba(255, 232, 26, 0.10); */
}

.NewPageTag2 p {
  /* color: #FFE81A; */
  font-family: Space Grotesk;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
}

.NewPageTag1 p {
  /* color: #8672FB; */
  font-family: Space Grotesk;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
}

.NewPageTagComponent img {
  width: 18px;
  height: 18px;
}


.NewPageUrl {
  display: flex;
  align-items: center;
  gap: 6.516px;
}

.NewPageUrl img {
  width: 10.86px;
  height: 10.86px;
}

.NewPageUrl p {
  color: #9393B8;
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.searchComponent{
  display: flex;
}
.hiddenInput {
  border-radius: 8px;
  border: 1px solid #62627a;
  color: #62627a !important;
  padding: 0 10px;
  width: 215px;
  margin-right: -22px;
  height: 42px;
  background: transparent;
  border: 1px solid #62627a;
}

.hiddenInput:focus {
  border: 1px solid #62627a;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(200%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(200%);
  }
}

.searchComponent input {
  transition: transform 0.5s;
}

.searchComponent input.moveLeft {
  animation: slideLeft 0.5s forwards;
}

.searchComponent input.moveRight {
  animation: slideRight 2s forwards;
}
.body-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.dark .body-container {
  background-color: black;
}

.left-component {
  margin: auto;
  width: 45%;
  height: 93vh;
  border-radius: 12px;
  border-radius: 8px;
  position: relative;
}

.left-component::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;

  /* background:linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)); */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
  background-blend-mode: normal;
  z-index: 2;
}

.left-component video {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.right-component {
  width: 40%;
  height: 100%;
  margin: auto;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-checkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formStyle {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.google-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 12px;
  height: 48px;
  border: 1px solid #9393b8 !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  margin-top: 10px;
}

.dark .google-login {
  background-color: #000 !important;
}

.google-button {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #9393b8;
}

.google-icon {
  background: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg")
    no-repeat;
  background-size: contain;
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
}

.labelInput {
  position: relative;
  margin-right: auto;
  width: 80%;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #9393b8;
}

.dark .labelInput {
  color: #c5c7d6;
}

.inputDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;
  height: 48px;
  background: #f0eded;
  border: 1px solid #31313d;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding: 0 40px;
  color: #000;
  position: relative;
}

.dark .inputDetails {
  background: #212129;
  border: 1px solid #31313d;
  color: #e3fcef;
}

.inputDetails::placeholder {
  padding: 0 10px;
}

#name {
  background-image: url(../../assets/icons/former/Contact.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
}

#email {
  background-image: url(../../assets/icons/sideBarHome/Vector.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
}

#password {
  background-image: url(../../assets/icons/former/Password.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
}

.inputDetails::placeholder {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9393b8;
}

.flexDisplay {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hyperLinkStyle {
  width: 264px;
  height: 31px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #808080;
  margin-top: 0;
}

.dark .hyperLinkStyle {
  color: #c5c7d6;
}

.headingStyle {
  width: 100%;
  height: 42px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: black;
  margin-bottom: 2px;
}

.dark .headingStyle {
  color: white;
}

/* #checkBox{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 31px;
    color: #7A7786;
    border:none;
    margin-top: 0px;
    margin-bottom: 0px;
} */

.TelephoneField {
  display: grid;
  grid-template-columns: 10% 90%;
}

.lembrar {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #808080;
  margin-left: 5px;
}

.Esqueceu {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #808080;
  margin-left: 5px;
  cursor: pointer;
}

.dark .lembrar {
  color: #c5c7d6;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #808080;
  border-radius: 2px;
}

.dark input[type="checkbox"] {
  border: 1px solid #c5c7d6;
}

input[type="checkbox"]:checked {
  background: #60f0d3;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  font-size: 1em;
  color: #101014;
  position: absolute;
  right: 1px;
  top: -5px;
}

.EnterButton {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  height: 48px;
  background: #60f0d3;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 15px 0;
}

.EnterButton:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  height: 48px;
  background: #affaeb;
  border-radius: 8px;
}

.button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 31px;
  color: #101014;
  transition: all 0.2s;
  margin-right: 10px;
}

.button--loaded {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  height: 48px;
  background: #2aeb81;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: -150px;
  right: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #101014;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.email-icon {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.password-icon {
  position: absolute;
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.togglePassword-icon {
  position: absolute;
  top: 65%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.errorBlock {
  padding: 10px 14px;
  margin: 20px 0;
  background: #e3fcef;
  border: 1px solid rgba(39, 174, 96, 0.3);
  border-radius: 4px;
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.errorMessage {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #c01818;
}

.successMessage {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #33303e;
}

@media only screen and (min-width: 768px) {
  .mobile-text {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .body-container {
    flex-direction: column;
  }

  .left-component {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 30%;
  }

  .right-component {
    margin: 0;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headingStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .hyperLinkStyle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-phone-view {
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    position: relative;
  }

  .mobile-text {
    position: absolute;
    top: 80%;
    left: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 11.086px 16.6289px;
    gap: 8.87px;
    width: 53px;
    height: 53.53px;
    background: #525266;
    border-radius: 8.12971px;
    z-index: 100;
  }

  .mobile-text p {
    width: 20px;
    height: 31px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 31.2055px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #e1e2eb;
  }

  .TelephoneField {
    display: grid;
    grid-template-columns: 15% 85%;
  }
}

.buildercontent_container {
  border: 2px solid green;
  width: 200px;
  height: 200px;
  margin-top: 10rem;
}

.buildercontent__element {
  border: 2px solid red;
}

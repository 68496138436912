.pagecontainer {
    width: 100%;

}

.sitesubbox {
    width: 80px;
    height: 99.5px;
    border-radius: 8px;
}

.sitebox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.switchPage {
    margin-top: 15px;
    height: 24px;
    width: 55px;
    border-radius: 24px;
}

.urlName {
    margin-top: 15px;
    height: 20px;
    width: 250px;
    border-radius: 8px;
}

.line3 {
    margin-top: 15px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.tags {
    height: 24px;
    width: 100px;
    border-radius: 24px;
}
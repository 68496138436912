/* Pranav Devkar */
.gerenciarMeus-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: #fff;
  width: 223px;
  height: 42px;
  background: transparent;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

.meusVideos-container {
  min-height: 100vh;
  width: 100%;
  background-color: #101014;
  color: #fff;
  padding-left: calc(5rem + 2.75rem);
  padding-right: 2.75rem;
}

.analyticDate {
  color: #bcbed1;
  padding: 6px 14px;
  width: 232px;
  border: 1px solid #62627a;
  border-radius: 4px;
  background: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1) hue-rotate(180deg);
  color: #bcbed1;

}

.meusVideos-header {
  padding: 2rem 0;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

}

.meusVideos-header div:nth-child(1) h3 {

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
}

.meusVideos-middle h2 {
  padding-bottom: 20px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.meusVideos-middle {
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
padding: 20px;
  /* height: 100%; */
  border-radius: 12px;
}

.meusVideos-middle-content {
  display: flex;
  width: 100%;
  gap: 20px;
}

.meusVideos-middle-first {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.meusVideos-middle-first button {
  padding: 12px 20px;
  background: #31313d;
  border: 1px solid #31313d;
  border-radius: 8px;
  text-align: left;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
}

.meusVideos-middle-last {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background: #31313d;
  border-radius: 12px;
  padding: 1rem;

  /* width: 100%; */
}

.player-banner {
  width: 100%;
}

.player-banner img {
  width: 100%;
  height: 100%;
}

.meusVideos-middle-last h3 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  padding-bottom: 14px;
}

.meusVideos-middle-last p {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  padding-bottom: 1rem;
}

.meusVideos-input-box {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;
  width: 284px;
  height: 36px;

  /* Dark colors/Dark 8 */

  background: #212129;

  /* Dark colors/Dark 5 * */

  border: 1px solid #525266;
  border-radius: 8px;
  margin-bottom: 20px;
}

.main-video-list-div {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid dimgray;
}

.sub1-video-list-div {
  display: flex;
  gap: 32px;
}

.listImg {
  width: 180px;
  height: 99.5px;
  border-radius: 8px;
}

.listHeader {
  /* padding-left: 32px; */
  align-self: center;
  font-family: 'Space Grotesk';
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #eef3ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.listtime {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

}

.actionimg {
  max-width: 100%;
}

.sub2-video-list-div {
  max-height: 15px;
  display: flex;
  align-self: center;
  gap: 20px;
  width: 108px;
  justify-content: space-between;
  height: 24px;
}

.back-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 30px;
  height: 30px;

  /* Dark colors/Dark 7 * */

  background: #31313d;
  border-radius: 4px;
  margin-right: 16px;
}

.metricas-btn {
  align-items: flex-start;
  padding: 2px 16px;
  gap: 8px;
  min-width: 100px;
  font-size: 14px;
  line-height: 31px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 10px;
  height: 34px;
  width: 99px;


  /* Dark colors/Dark 8 */

  background: #212129;
  border-radius: 8px;
}

.ajustes {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
}

.graphArea {
  background: #fff;
}

.meusVideos-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  gap: 12px;
  width: 232px;
  border: 1px solid #62627a;
  border-radius: 4px;
  background: transparent;
}

.btn-success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  font-size: 14px;
  margin-left: 10px;
  width: 114px;
  height: 36px;
  background: #11d167;
  border-radius: 6px;
}

.clock-text {
  font-size: 12px;
}

.graph-card {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 20px;
  gap: 4px;
  width: 242px;
  height: 98.2px;

  /* Dark colors/Dark 7 * */

  background: #31313d;

  /* Dark colors/Dark 7 * */

  border: 1px solid #31313d;
  border-radius: 8px;
  margin-bottom: 12px;
}

.magic-progress {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #e1e2eb;
}

.magic-progress-numbers {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.maingraphdiv {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.graph-area {
  width: 80vw;
}

.cards-area {
  width: 20vw;
}

.graph-box,
.graph-area {
  background-color: #fff;
  height: 100%;
}

.display-flex-justify-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-align-center {
  display: flex;
  align-items: center;
}

.clock-para {
  display: flex;
  align-items: center;
}

.disply-flex-justify-start {
  display: flex;
  justify-content: start;
}

.disply-flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.pl-lg-5-clock {
  padding-left: 3rem;
}
.stream {
  width: 900px;
  border-radius: 20px;
}

.chart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -500px;
  width: 1020px;
  height: 560px;
}

@media (max-width: 1405px) {
  .stream {
    width: 700px;
    border-radius: 20px;
  }
  
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -400px;
    width: 800px;
    height: 450px;
  }
}
@media (max-width: 1205px) {
  .stream {
    width: 500px;
    border-radius: 20px;
  }
  
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -300px;
    width: 600px;
    height: 350px;
  }
}

/* Tablet View */
@media (max-width: 768px) {
  .display-flex-justify-align-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0;
  }

  .pl-lg-5-clock {
    padding-left: 0;
  }

  .display-flex-justify-start {
    display: grid !important;
    justify-content: flex-start;
  }

  .display-flex-justify-space-between {
    display: grid !important;
    justify-content: space-between;
  }

  .disply-flex-justify-start {
    display: grid;
    justify-content: start;
  }

  .graph-area {
    width: 100%;
  }

  .cards-area {
    width: 100%;
  }

  .btn-success {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    gap: 8px;
    font-size: 14px;
    margin-left: 0;
    width: 100%;
    height: 36px;
    margin: 10px 0;
    background: #11d167;
    border-radius: 6px;
  }

  .meusVideos-dropdown {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 14px;
    gap: 12px;
    width: 100%;
    border: 1px solid #62627a;
    border-radius: 4px;
  }

  .clock-para {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  /* .maingraphdiv {
    display: grid;
    justify-content: center;
  } */

  /* .meusVideos-container {

        padding-left: calc(2rem + 2.8rem);
        padding-right: 2.8rem;
    } */
  .stream {
    width: 900px;
  }

  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -509px;
    width: 200px;
    height: 200px;
  }

  .graph-card {
    width: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .meusVideos-header {
    display: grid;
    justify-content: space-between;
  }
}




/* --------------------------------------------Código de incorporação para sites externos---------------------------------- */

.switchboard .title {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  color: #fff;
}

.switchboard .dropdown-row {
  display: flex;
  justify-content: space-between;
}

.drop-down-title {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #fff;
  padding-top: 24px;
  padding-bottom: 10px;
}

.switchboard-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px;
  gap: 12px;
  width: 100%;
  border: 1px solid #62627a;
  border-radius: 4px;
}

.color-block {
  width: 36px;
  height: 36px;
  margin-right: 5px;
  background: #60f0d3;
  border-radius: 4px;
}

.color-block-flex {
  display: flex;
  justify-content: space-around;
}
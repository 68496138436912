* {
  color: white;

}

p {
  font-size: 14px;
}
.checked{
  background-color: #11d167;
}
.unChecked{
  background-color: #e9e9ea;
}
.loading{
  cursor: not-allowed !important;
}
.notLoading{
  cursor: pointer !important;
}
.mainvideodiv {
  display: flex;
  justify-content: space-between;
  background: #101014;
}

.Toastify__toast-body>div:last-child {
  color: black;
}

.frameBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 44px 0 44px; */
  gap: 24px;
  width: 100%;
  height: 100vh;
  font-family: "Space Grotesk";
  left: 76px;
  top: 90px;
  /* background:#101014 ; */
}

.frameBody h4 {
  font-weight: 500 !important;
    line-height: 16px;
    font-size: 16px;
}
.domain-box{
  display: flex;
  width: 640px;
  /* height: 320px; */
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--dark-colors-dark-8, #212129);}
.RightComponentLanding {
  width: 100%;
  height: 103vh;
  flex: 1 !important;
  display: flex;
  flex-direction: column;
  /* margin-left: 75px; */
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding-left: calc(5rem + 2.75rem);
  padding-right: calc(5rem + -2.25rem);
  /* padding-right: 2.75rem;   */

  scrollbar-width: none;
}

/* .frameGrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  height: 634px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  justify-content: center;
} */

.gridBody {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 0 32px; */
  gap: 24px;
  width: 100%;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: center;
}

.frameTopGrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  margin: 0 auto;
  width: 100%;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: center;
}

.file_uploadtitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.authDomainselect {
  width: 65%;
}

.frameBottomGrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  height: auto;
  width: 100%;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
}

.inputFieldBox3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;


  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
}

.inputFieldBox3 .fileUploadDiv {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 45px;
  gap: 8px;
  width: 100%;
  height: 223px;

  /* Dark colors/Dark 7 * */

  background: #31313d;

  /* Dark colors/Dark 5 * */

  border: 1px dashed #525266;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.fileUploadDiv {
  box-sizing: border-box;
  cursor: pointer;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 45px;
  gap: 8px;
  width: 100%;
  height: 174px;
  background-size: contain;

  /* Dark colors/Dark 7 * */

  background-color: #31313d;

  /* Dark colors/Dark 5 * */

  border: 1px dashed #525266;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}
.fileBoxSize{
  height: 174px !important;
}

.inputField {
  width: 100%;
}

.inputFieldBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;
  height: 62px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}


.magicAutoplayTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;
  width: 339px;
  height: 18px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.magicAutoplayTitle div{
  height: 100%;
}
.magicFileBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.file_uploadframe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 95%;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.uploadFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.uploadFrame p a{
  color: #006FD6;
  text-decoration: underline;
  margin-left: 2px;
  line-height: 16.8px;


}
.uploadFrame p {
  color: #8383A3;
  line-height: 16.8px;
}

.frame4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;
  /* width: 339px; */
  height: 18px;
  flex: none;
  /* order: 0; */
  flex-grow: 0;
}

.inputFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  height: 62px;
  flex: none;
  /* order: 1; */
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  justify-content: space-between;
}

.frame38221 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  height: 62px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
}

.toggleDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  width: 100%;
  height: 94px;


  /* Inside auto layout */

  flex: none;
  /* order: 2; */
  align-self: stretch;
  flex-grow: 0;
}

.toggleDiv .row1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;
  height: 18px;
  margin-top: 5px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  justify-content: space-between;
}

input[type="color"] {
  appearance: none;

}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border-radius: 4px;
}

.colorInput {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.colorInput input:nth-child(2) {
  border-radius: 4px;
  height: 36px;
  width: 36px;
}

.tamanhoDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 14px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border: 1px solid #525266;
  border-radius: 4px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  color: #fff;
  margin-top: 10px !important;
}

.colorText {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 14px;
  color: #fff;
  width: 77%;
  height: 36px;

  /* Dark colors/Dark 5 * */

  border: 1px solid #525266;
  border-radius: 4px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
}


.codigoCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 100%;

  /* Inside auto layout */
  flex: none;
  /* order: 3; */
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 20px; */
}
.codigoCard textarea {
  min-height: 110px;
  background-color: transparent;
}
.embedCodePara {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 14px;
  width: 100%;

  /* Dark colors/Dark 5 * */

  border: 1px solid #525266;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

/* Switch Button*/

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 48px;
  top: -12px;
  left: 300px;
}

.toggleSwitchCheckbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitchLabel {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 24px;
  background-color: #d1d1d1;
}

.toggleSwitchInner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  background-color: #60f0d3;
  height: 24px;
  border-radius: 24px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.dark .toggleSwitchInner {
  background-color: #fff;
}

.toggleSwitchCheckbox:checked+.toggleSwitchLabel .toggleSwitchInner {
  margin-left: 0%;
}

.toggleSwitchSwitch {
  display: block;
  position: absolute;
  top: 24px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  transform: translate3d(2px, 2px, 0);
  transition: transform 0.3s ease-in-out 0s;
}

.toggleSwitchCheckbox:checked+.toggleSwitchLabel .toggleSwitchSwitch {
  transform: translate3d(26px, 2px, 0);
}



.inputFieldBox3 .file_uploadframe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 223px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}


.authDomain {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0;
  gap: 8px;
  height: 62px;
  margin: 23px 0;
  /* margin-bottom: 2px !important; */
  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.videoBox1 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  width: 150px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.videoBox2 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  width: 150px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.SalvarBtn {
  /* padding: 12px; */
  background: rgb(17, 209, 103);
  color: #000;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  height: 36px;
    width: 148px;
}

.embedButton1 {
  background: rgb(17, 209, 103);
  color: #000;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  /* padding: 0 24px 0 24px; */
  height: 36px;
  width: 148px;

}

.embedButtonCopy {
  /* padding: 12px; */
  background: white;
  color: #000;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  width: 129px;
  height: 36px;

}

.EmbedButtons {
  display: flex;
  gap: 15px;
}

.dropdown2Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 14px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border: 1px solid #525266;
  border-radius: 4px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  color: #fff;
}


.dropdownMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  gap: 14px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border: 1px solid #525266;
  border-radius: 4px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  color: #fff;
}

.AdicionarBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 8px;
  height: 36px;
  width: 35%;
  /* Light colors/Light 1 * */

  background: #fff;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #101014;
}

.leftInputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  height: 62px;
  width: 50%;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.frameRight1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 50%;
  height: 62px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.frameRight2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 50%;
  height: 62px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  width: 50%;
}

.rightInputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  height: 62px;
  width: 46%;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.inputVideoName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 3px;
  border: 1px solid #525266;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
}

.HeaderNovaPagina {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  gap: 8px;
  width: 107%;
  height: 90px;
  left: 75px;
  top: 0;
  /* background: transparent; */
}


.frameBottomGrid .magicAutoplayBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  width: 150px;
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.switch {
  position: relative;
  display: inline-block;
  /* width: 48px; */
  /* height: 34px; */
  width: 36px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(17, 209, 103); */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  /* height: 24px; */
  /* width: 26px; */
  height: 14px;
  width: 14px;
  bottom: 2px;
  right:19px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0px 0px 3px 0px #00000080;

}

input:checked+.slider {
  background-color: rgb(17, 209, 103);
}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(17, 209, 103);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  right: 28px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkBoxLabel {
  /* width: 86px; */
  height: 12px;
  display: inline-block;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;

  /* identical to box height, or 12px */
  display: flex;
  align-items: center;

  /* Light colors/Light 1 * */

  color: #fff;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 10px;
}

.toggleColumn1 {
  display: flex;
  justify-content: center;
  align-items: center;
}





@media (max-width: 1000px) {
  .frameTopGrid {
    flex-direction: column;
  }

  .videoBox1 {
    width: 100%;
  }

  .videoBox2 {
    width: 100%;
    /* margin-bottom: 3rem; */
  }

  .rightInputBox2 {
    width: 13rem;
    order: 1;

  }


  .frameHeader {
    justify-content: end;
  }

  .HeaderNovaPagina p {
    flex-wrap: wrap;
    font-size: small;
    line-height: 17px;
  }
/* 
  .frameBottomGrid {
    margin-top: 20rem;
  } */

  .inputFieldBox {
    flex-direction: column;
    height: auto;
  }

  .authDomain {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 45px;
  }

  .file_uploadframe {
    width: 100%;
  }

  .frameRight1 {
    height: initial;
    width: 100%;
  }

  .frameRight2 {
    height: initial;
    width: 100%;
  }

  .dropdown2Menu {
    max-width: 20rem;
  }

  .frameBottomGrid {
    flex-direction: column;
  }

  .frameBottomGrid .magicAutoplayBox {
    width: 100%;
  }

  .embedCodePara p {
    overflow: auto;
  }

  .inputFrame {
    height: auto;
    flex-wrap: wrap;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

.frazer-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.813);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  color: #fff;

  .frazer-box {
    display: flex;
    width: 640px;
    // height: 320px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @include border-radius(12px);
    background: var(--dark-colors-dark-8, #212129);

    .frazer-upload-header {
      display: flex;
      width: 640px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid var(--dark-colors-dark-7, #31313D);
      background: var(--dark-colors-dark-8, #212129);
      @include border-radius(12px);

      h2 {
        color: var(--light-colors-light-1, #FFF);
        font-family: Space Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
      }

      img {
        cursor: pointer;
      }
    }

    .minimum-height {
      display: flex;
      width: 640px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .connectModalSiteContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 233px;
    }

    .frazer-input {
      padding: 20px;
      display: flex;
      flex-direction: column;

      label {
        color: var(--light-colors-light-1, #FFF);
        font-family: Space Grotesk;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
      }

      input {
        display: flex;
        width: 409px;
        height: 36px;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
      }

      p {
        width: 30%;
        padding: 0.5rem 1rem;
        text-align: center;
        border: 1.5px solid #fff;
        @include border-radius(10px);
        margin-bottom: 1.5rem;
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 31px;
        color: #bcbed1;
      }
    }

    .notes-box {
      display: flex;
      padding: 12px 20px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      @include border-radius(4px);
      border-left: 4px solid var(--system-colors-yellow-02, #FFE81A);
      background: rgba(245, 212, 0, 0.10);
      margin: 0 1.5rem 4rem !important;
      width: 409px;

      .popup-note {
        color: var(--light-colors-light-1, #FFF);
        font-family: Space Grotesk;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        flex: 1 0 0;
      }
    }

    .fazer-btn {
      display: flex;
      padding: 16px 20px;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      border-top: 1px solid var(--dark-colors-dark-7, #31313D);
      background: var(--dark-colors-dark-8, #212129);
      @include border-radius(0 0 12px 12px);

      p {
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 31px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }

      button {
        background: #11d167;
        @include border-radius(8px);
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 31px;
        height: 36px;
        color: #101014;
        padding: 0 20px 0 24px; /* padding: 0.5rem 1.2rem; */
      }
    }
  }

  @media screen and (max-width: 854px) {
    .frazer-box {
      background: #212129;
      @include border-radius(12px);
      width: 80%;
    }

    .frazer-input p {
      width: 40%;
      padding: 0.5rem 1rem;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

.atomicplayer-container {
  min-height: 100vh;
  width: 100%;
  background-color: #101014;
  color: #fff;
  padding-left: calc(5rem + 2.8rem);
  padding-right: 2.8rem;
}

.atomicplayer-header {
  padding: 1.8rem 0;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.atomicplayer-header button {
  background: #fff;
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: #101014;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.5rem 1.2rem;
}

.atomicplayer-middle h2 {
  padding-bottom: 20px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.atomicplayer-middle {
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
  border-radius: 12px;
  padding: 20px;
}

.atomicplayer-middle-content {
  display: grid;
  grid-template-columns: 30% calc(70% - 20px);
  width: 100%;
  gap: 20px;
}

.atomicplayer-middle-first {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
}

.atomicplayer-middle-first button {
  padding: 12px 20px;
  background: #31313d;
  border: 1px solid #31313d;
  border-radius: 8px;
  text-align: left;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
}

.activebtn {
  background:
    linear-gradient(
      315deg,
      #8672fb 0.94%,
      #60f0d3 48.36%,
      #a8d916 121.83%
    ) !important;
  color: #101014 !important;
}

.atomicplayer-middle-last {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background: #31313d;
  border-radius: 12px;
  padding: 1rem;

  /* width: 100%; */
}

.player-banner {
  width: 100%;
}

.player-banner img {
  width: 100%;
  height: 100%;
}

.atomicplayer-middle-last h3 {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  padding-bottom: 14px;
}

.atomicplayer-middle-last p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  padding-bottom: 1rem;
}

.atomicPlayer-plan {
  background: #212129;
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.atomicPlayer-plan-heading {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.atomicPlayer-plan-category {
  background: #31313d;
  border-radius: 100px;
  width: calc((100% - 40px) / 3);
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}

.atomicPlayer-plan-category button {
  width: 100%;
  padding: 1rem;
  border-radius: 100px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #d3d4e0;
}

.atomicPlayer-plan-category button:last-child {
  background: #fff;
  color: #020818;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.atomicPlayer-plan-category button:last-child span {
  background: #11d167;
  border-radius: 100px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 9px;
  letter-spacing: 0.02em;
  color: #000;
}

.hr80 {
  border-bottom: 1px solid #31313d;
  width: 100%;
}

.atomicPlayer-plan-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.atomicPlayer-plan-list-item {
  background: #101014;
  border: 1px solid #31313d;
  border-radius: 12px;
  padding: 1rem;
}

.atomicPlayer-plan-list-item-title {
  background: rgba(211, 212, 224, 0.1);
  border: 1px solid #d3d4e0;
  border-radius: 200px;
  padding: 4px 20px;
  margin-bottom: 2rem;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #d3d4e0;
  text-transform: uppercase;
  cursor: default;
}

.atomicPlayer-plan-list-item .actual-price {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2rem;
  letter-spacing: -0.02em;
  color: #d3d4e0;
  margin-bottom: 1.5rem;
}

.atomicPlayer-plan-list-item .actual-price span {
  color: #ff4258;
}

.atomicPlayer-plan-list-item .offerPrice {
  margin-bottom: 1.3rem;
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
}

.offerPrice h1 {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
}

.offerPrice p {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #bcbed1;
}

.price-desc {
  margin-bottom: 1.5rem;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #bcbed1;
}

.price-desc span {
  color: #e1e2eb;
}

.plan-btn {
  width: 100%;
  margin: 2rem 0 1rem;
  border: 2px solid #fff;
  padding: 1rem;
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #fff;
  text-transform: uppercase;
}

.plan-feature {
  margin-top: 2rem;
}

.plan-feature ul {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}

.plan-feature ul li {
  display: flex;
  gap: 1rem;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #d3d4e0;
}

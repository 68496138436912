.HeaderNovaPagina {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 44px;
  gap: 8px;
  width: 100%;
  height: 90px;
  left: 75px;
  top: 0;
  background: #101014;
}

.HeaderNovaPagina p {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
}

.css-13cymwt-control {
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%) !important;
  border: 1px solid #62627a !important;
}

.css-t3ipsp-control {
  background: linear-gradient(180deg, #212129 0%, #1f1f26 100%) !important;
  z-index: 10;
}

.css-1p3m7a8-multiValue {
  background: darkgrey !important;
}

.select__menu {
  background: black !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-ypiucp-control {
  z-index: 9;
}

.css-674r7s-control {
  z-index: 9;
}

.frameHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: 404px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
}

.selectedComponent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  /* height: 34px; */
  background: #212129;
  border-radius: 8px;
  cursor: not-allowed;
}

.unselectedComponent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  cursor: pointer;
}

.componentName {
  height: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #fff;
}

/* .frameBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 44px 32px;
  gap: 24px;
  width: 100%;
  height: 726px;
  left: 76px;
  top: 90px;
} */

.componentsPagina {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: 0;
  gap: 32px;
  width: 100%;
  height: 292.85px;
  margin: 30px;
}

.page1 {
  width: 217.36px;
  height: 268.85px;
  background: linear-gradient(180deg, #bcbed1 -19.46%, rgba(188, 190, 209, 0) 84.68%), #fff;
  border-radius: 8px;
  position: relative;
}

.page1div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 6px;
  position: absolute;
  width: 77.82px;
  height: 19px;
  left: 70px;
  top: 28.22px;
}

.page1div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.9357px 5.90355px;
  gap: 3.15px;
  width: 18.82px;
  height: 19px;
  background: #31313d;
  border-radius: 2.88618px;
}

.page1div p {
  width: 53px;
  height: 18px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #31313d;
}

.page1text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 8px;
  position: absolute;
  width: 185px;
  height: 56px;
  left: 19px;
  top: 198px;
  z-index: 10;
}

.page1text p {
  width: 185px;
  height: 14px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #212129;
}

.page1text span {
  width: 158px;
  height: 34px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #414152;
}

.page1New {
  position: absolute;
  width: 142.68px;
  height: 187px;
  left: 43px;
  top: 63px;
  z-index: 10;
}

.page1New p {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 52px;
  top: 74px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcbed1;
  font-size: 24px;
}

.page1page {
  box-sizing: border-box;
  position: absolute;
  width: 128px;
  height: 165px;
  left: 7px;
  top: 7px;
  border-radius: 2px;
}

.page1pageOut {
  position: absolute;
  width: 134.68px;
  height: 164px;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #fff 45.19%, rgba(255, 255, 255, 0) 86.9%);
  border-radius: 4.3237px;
}

.ellipse {
  position: absolute;
  width: 217.36px;
  height: 328px;
  left: 0;
  top: 92px;
  border-radius: 30%;
  background: linear-gradient(180deg, #bcbed1 -33.8%, rgba(188, 190, 209, 0) 42.68%);
  z-index: 1;
}

.pages {
  width: 217.36px;
  height: 282.85px;
}
.pages:hover .pageButton {
  visibility: visible; 
}
.pages .pageButton {
  visibility: hidden;
}
.pages:hover img {
  filter: blur(2px); 
}
.visualizeButton {
  /* height: 34px; */
  width: 173.33px;
  Padding: 10px 14px 10px 14px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  /* text-align: left; */

}

.selectButton {
  width: 173.33px;
  Padding: 10px 14px 10px 14px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  background-color: #fff;
  color: #101014;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;

}

.pageButton {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  bottom: 62%;
  left: 10%;
}
.page-container{
  position: fixed;
    top: -10px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.813);
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    color: #fff;
}
.page-box{
  display: flex;
    width: 85%;
    /* height: 320px; */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.page-header{
  display: flex;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    background: #31313D;


}
.page-header h2{
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

.dateSpan {
  white-space: nowrap;
}

.inputThird {
  border-radius: 8px;
  border: 1px solid #62627a;
  color: #62627a !important;
  padding: 0 10px;
  width: 300px;
  margin-right: -52px;
  height: 42px;
  background: transparent;
  border: 1px solid #62627a;
}

.inputThird:focus {
  border: 1px solid #62627a;
}


.switch {
  position: relative;
  display: inline-block;
  /* width: 48px; */
  /* height: 34px; */
  width: 36px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(17, 209, 103); */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  /* height: 24px; */
  /* width: 26px; */
  height: 14px;
  width: 14px;
  bottom: 2px;
  right:19px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0px 0px 3px 0px #00000080;

}

input:checked+.slider {
  background-color: rgb(17, 209, 103);
}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(17, 209, 103);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  right: 28px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkBoxLabel {
  /* width: 86px; */
  height: 12px;
  display: inline-block;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;

  /* identical to box height, or 12px */
  display: flex;
  align-items: center;

  /* Light colors/Light 1 * */

  color: #fff;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 10px;
}

.SwitchFlex{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
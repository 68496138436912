.headerTutorial{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    width: 100%;
    height: 90px;
}
.headerTutorial p {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: capitalize;
}
.tutorialfilter{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    /* width: 404px; */
    height: 34px;
}
.tutorialSearch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    gap: 12px;
    width: 260px;
    height: 42px;
    background: #212129;
    border: 1px solid #62627a;
    border-radius: 8px;
    color: #fff;
}
.tutorialBox{
    background: linear-gradient(180deg, #212129 0%, #1f1f26 100%);
    /* height: 100%; */
    border-radius: 12px;
    padding: 20px;
}
.tutorialList{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid dimgray;
}
.headerTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 32px 0;
    gap: 8px;
    width: 100%;
    height: 90px;
}
.headerTitle p {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #fff;
}
.categoryName{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.dark .dark\:text-sky-500{
    --tw-text-opacity: 1;
    color: rgb(14 165 233/var(--tw-text-opacity));
}
.dark .dark\:font-normal {
    font-weight: 400;
}
.dark .dark\:bg-sky-900\/20 {
    background-color: rgba(12,74,110,.2);
}
.dark .dark\:text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129/var(--tw-text-opacity));
}
.dark .dark\:bg-emerald-900\/20 {
    background-color: rgba(6,78,59,.2);
}
.dark .dark\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgb(168 85 247/var(--tw-text-opacity));
}
.dark .dark\:bg-purple-900\/20 {
    background-color: rgba(88,28,135,.2);
}
.dark .dark\:text-violet-500 {
    --tw-text-opacity: 1;
    color: rgb(139 92 246/var(--tw-text-opacity));
}
.dark .dark\:bg-violet-900\/20 {
    background-color: rgba(76,29,149,.2);
}
.iconPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.813);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.iconPopupHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 2px solid #31313d;
}

.iconPopupHeader .headerText {
  font-size: 30px;
  font-weight: 700;
}

.iconPopupContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #212129;
  border-radius: 12px;
  width: 70%;
  height: 100%;
  overflow-y: visible;
  position: relative;
}

.iconPopuBodyNav {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
}

.navButton {
  text-align: left;
  padding: 15px 0 15px 45px;
}

.activeNavButton {
  position: relative;
  text-align: left;
  background-color: #333438;
  padding: 15px 0 15px 45px;
}

.activeNavButton::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  left: 0;
  background-color: #fff;
}

.searchIconContainer {
  flex-basis: 75%;
  padding: 28px;
}

.searchIconContainer input {
  width: 85%;
  outline: transparent;
  border: transparent;
  background-color: transparent;
}

.searchIconContainer input:focus {
  outline: none;
  border: none;
}

.searchIconContainer input:active {
  outline: none;
  border: none;
}

.selectedIconComponent {
  overflow-y: scroll;
  height: 500px;
}

.selectedIcon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 111px;
  width: 111px;
  align-items: center;
  justify-content: center;
  margin: 8px;
  border-radius: 6px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.selectedIcon:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.AddButton{
  text-align: right;
  padding: 1rem;
  border-top: 2px solid #31313d;
  position: relative;
  z-index: 1;
}